import { css } from '@emotion/react';
import {
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
} from 'react-feather';
import {
  MemoizedGallery,
  GalleryProps,
  Layout,
} from 'components/_shared/widgets/gallery';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Heading, Text } from 'components/typography';
import { Flex, Box, Grid } from 'components/box';
import { Shop as ShopType } from 'types/types';
import Color from '../../util/color';
import animatedScroll from '../../util/animated-scroll';
import Button from 'components/button';
import { useResized } from 'hooks/display';

export type ShopProps = ShopType & {
  galleries: GalleryProps[];
  totalItems: number;
};

export const SHOP_ITEMS_PER_GALLERY = 12;
export const SHOP_GALLERY_LAYOUT: Layout = [2, 3, 4];

export const getRandomColor = () => {
  const colors = ['#0DBAB3', '#E5AF0E', '#3A3B46', '#05be99'];
  return colors[Math.floor(Math.random() * colors.length)];
};

const SHOP_ROW_COUNT = 4;
const SHOP_FULL_HEIGHT = 570;

export const ShopSkeleton = ({
  id,
  name,
  color,
  galleries: [firstGallery],
  isExpanded,
  height,
  totalItems,
}: ShopProps & { height?: number; isExpanded: boolean }) => (
  <div
    css={css`
      position: relative;
      ${height &&
      css`
        height: ${height}px;
      `}
    `}
  >
    <Flex
      id={id}
      mb={['5px', null, 0]}
      px={4}
      py={2}
      justifyContent="space-between"
      alignItems="center"
      css={theme => css`
        width: 100%;
        min-height: 66px;
        background: linear-gradient(
          to right,
          ${Color(color).lighten(0.1).string()},
          ${Color(color).string()}
        );

        @media ${theme.mediaQueries.desktopUp} {
          border-top-right-radius: 12px;
          border-top-left-radius: 12px;
        }

        @media ${theme.mediaQueries.mobileOnly} {
          margin-left: -${theme.space[3]}px;
          margin-right: -${theme.space[3]}px;
          border-width: 0 0 1px 0;
          width: calc(100% + ${theme.space[3] * 2}px);
        }

        &:active,
        &:focus {
          outline: 0;
        }
      `}
    >
      <Heading
        color="white"
        textAlign="left"
        fontWeight={700}
        fontSize={4}
        css={css`
          user-select: none;
        `}
      >
        {`${name} Shop`}
      </Heading>
    </Flex>

    <div
      css={theme => css`
        overflow: hidden;
        padding: 16px;
        margin-bottom: 32px;

        @media ${theme.mediaQueries.mobileOnly} {
          padding: 16px 0;
          margin-bottom: 0;
        }

        ${!isExpanded &&
        css`
          @media ${theme.mediaQueries.desktopUp} {
            height: ${totalItems <= SHOP_ROW_COUNT ? 410 : 550}px;
          }
          height: 450px;
        `}
      `}
    >
      <MemoizedGallery {...firstGallery} isSkeleton />
    </div>

    <div
      css={css`
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 0;
        width: 100%;
      `}
    >
      <div
        css={css`
          background: linear-gradient(
            to right,
            ${Color(color).lighten(0.1).string()},
            ${Color(color).string()}
          );
          border-radius: 10px;
          margin: 25px 0;
          width: 200px;
          height: 32px;
          ${isExpanded &&
          css`
            margin: 7px 0;
          `}
        `}
      />
    </div>
  </div>
);

/**
 * Shop re-renders when expanding/collapsing would trigger gallery re-renders.
 * To avoid this, we use a separate memoized gallery component.
 * This doesn't guarantee that the gallery will never re-render, it just reduces re-renders.
 * We have however also reduced the amount of processing per gallery render,
 * so having rare re-renders isn't the end of the world.
 */
const Shop = ({
  totalItems,
  color,
  name,
  image,
  position,
  galleries,
  isExpanded,
  expandCallback,
  collapseCallback,
  setShopFullHeight,
  shopFullHeight,
  ...rest
}: ShopProps & {
  isExpanded: boolean;
  expandCallback: () => void;
  collapseCallback: () => void;
  shopFullHeight?: number;
  setShopFullHeight: Dispatch<SetStateAction<number>>;
}) => {
  const overflowCount = totalItems - SHOP_ROW_COUNT;

  const refCallback = (node: HTMLDivElement | null) => {
    if (node) {
      const shopHeight = node.scrollHeight;
      setShopFullHeight(shopHeight);
    }
  };

  return (
    <Box {...rest}>
      <Flex
        px={[3, 4]}
        py={2}
        alignItems="center"
        css={theme => css`
          min-height: 66px;
          background: linear-gradient(
            to right,
            ${Color(color).lighten(0.1).string()},
            ${Color(color).string()}
          );

          @media ${theme.mediaQueries.tabletUp} {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
          }

          @media ${theme.mediaQueries.mobileOnly} {
            margin-left: -${theme.space[3]}px;
            margin-right: -${theme.space[3]}px;
          }
        `}
      >
        <Heading
          tabIndex={0}
          aria-label={`${name} Shop`}
          color="white"
          textAlign="left"
          fontWeight={700}
          fontSize={4}
          css={css`
            user-select: none;
          `}
        >
          {name}
        </Heading>
      </Flex>

      <Grid
        bg="shopGrey"
        mb={[3, null, 4]}
        css={theme => css`
          position: relative;
          overflow: hidden;
          overflow-anchor: none;
          row-gap: 0px; /* crucial */
          border: 1px solid ${theme.colors.mediumGrey};
          user-select: none;

          @media ${theme.mediaQueries.tabletUp} {
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
          }

          @media ${theme.mediaQueries.mobileOnly} {
            padding: 0 0 ${theme.space[3]}px;
            margin-left: -${theme.space[3]}px;
            margin-right: -${theme.space[3]}px;
            border-width: 0 0 1px 0;
          }
        `}
      >
        {overflowCount > 0 && (
          <>
            <Box
              className={!isExpanded ? 'collapsed' : ''}
              css={theme => css`
                position: absolute;
                z-index: 140;
                bottom: 0;
                width: 100%;
                height: 150px;
                @media ${theme.mediaQueries.tabletUp} {
                  height: 100px;
                }
                @media ${theme.mediaQueries.desktopUp} {
                  height: 135px;
                }

                display: none;

                &.collapsed {
                  display: block;
                  background-image: linear-gradient(
                    to bottom,
                    #eff1f300,
                    #eff1f3aa,
                    #eff1f3ff
                  );

                  @media ${theme.mediaQueries.tabletUp} {
                    background-image: linear-gradient(
                      to bottom,
                      #eff1f300,
                      #eff1f366,
                      #eff1f3dd
                    );
                  }
                }
              `}
            />

            <Flex
              justifyContent="center"
              px={[3, 0]}
              position="absolute"
              bottom={0}
              width="100%"
              css={theme => css`
                @media ${theme.mediaQueries.mobileOnly} {
                  margin-left: -${theme.space[3]}px;
                  margin-right: -${theme.space[3]}px;
                  width: calc(100% + ${theme.space[3] * 2}px);
                }
              `}
            >
              <Button
                px={3}
                mt={1}
                py="10px"
                mb={['10px', '20px']}
                display="flex"
                borderRadius="3px"
                color={color}
                aria-label={
                  !isExpanded ? `Shop ${overflowCount} more deals` : `Show less`
                }
                onClick={() =>
                  isExpanded ? collapseCallback() : expandCallback()
                }
                css={theme => css`
                  border: none;
                  letter-spacing: normal;
                  outline: revert;
                  outline-offset: 4px;
                  justify-content: center;
                  align-items: center;
                  z-index: 140;

                  @media ${theme.mediaQueries.mobileOnly} {
                    width: 100%;
                  }
                `}
              >
                <Text
                  fontSize="r"
                  color="white"
                  fontWeight="bold"
                  fontFamily="header"
                  css={css`
                    text-transform: uppercase;
                  `}
                >
                  {!isExpanded
                    ? `Shop ${overflowCount} more deals`
                    : `Show less`}
                </Text>

                {!isExpanded ? (
                  <ChevronDownIcon
                    size={20}
                    css={css`
                      color: white;
                      opacity: 0.8;
                      margin-left: 15px;
                    `}
                  />
                ) : (
                  <ChevronUpIcon
                    size={20}
                    css={css`
                      color: white;
                      opacity: 0.8;
                      margin-left: 15px;
                    `}
                  />
                )}
              </Button>
            </Flex>
          </>
        )}

        <Box
          className={isExpanded ? 'expanded' : ''}
          css={theme => css`
            position: relative;
            transition: height 0.3s ease;
            @media ${theme.mediaQueries.desktopUp} {
              height: ${totalItems <= SHOP_ROW_COUNT ? 410 : 550}px;
            }
            height: 450px;
            &.expanded {
              height: ${shopFullHeight}px;
            }
          `}
        >
          <Box
            ref={refCallback}
            px={[3, null, 4]}
            pt={[3, null, 4]}
            pb={['40px', '60px']}
          >
            {galleries.map((galleryProps, idx) => (
              <MemoizedGallery
                key={idx}
                isSkeleton={idx !== 0 && !isExpanded}
                {...galleryProps}
              />
            ))}
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

let timeoutId: ReturnType<typeof setTimeout> | undefined;

const SCROLL_OFFSET = 500;
const SCROLL_DURATION = 350;
const SCROLL_MAX_WAIT_TIME = 750;

const Wrapper = ({
  isSkeleton,
  ...restProps
}: ShopProps & { isSkeleton?: boolean }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [shopActiveHeight, setShopActiveHeight] = useState(0);
  const [shopFullHeight, setShopFullHeight] = useState(SHOP_FULL_HEIGHT);
  const [observedHeight, setObservedHeight] = useState(0);

  const collapseCallback = useCallback(() => {
    // collapse immediately if we don't have access to the window or a working ref
    if (typeof window === 'undefined' || !ref.current) {
      setIsExpanded(false);
      return;
    }

    animatedScroll({
      target: ref.current.offsetTop + SCROLL_OFFSET,
      duration: SCROLL_DURATION,
      onComplete: () => {
        timeoutId && clearTimeout(timeoutId);
        setIsExpanded(false);
      },
    });

    // collapse automatically after maximum wait time.
    timeoutId = setTimeout(() => setIsExpanded(false), SCROLL_MAX_WAIT_TIME);
  }, [ref]);

  useEffect(() => {
    !isSkeleton && setShopActiveHeight(observedHeight);
  }, [isSkeleton, observedHeight]);

  const resizeCallback = useCallback((entry: ResizeObserverEntry) => {
    entry.contentRect && setObservedHeight(entry.contentRect.height);
  }, []);

  useResized({
    ref,
    callback: resizeCallback,
  });

  return (
    <section ref={ref}>
      {isSkeleton ? (
        <ShopSkeleton
          {...restProps}
          isExpanded={isExpanded}
          height={shopActiveHeight}
        />
      ) : (
        <Shop
          {...restProps}
          isExpanded={isExpanded}
          expandCallback={() => setIsExpanded(true)}
          collapseCallback={collapseCallback}
          shopFullHeight={shopFullHeight}
          setShopFullHeight={setShopFullHeight}
        />
      )}
    </section>
  );
};

export default Wrapper;
