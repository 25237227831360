import { EasingType, ease } from './easing';
import { isBrowserWindowAvailable } from './is-browser-window-available';

const animatedScroll = ({
  target = 0,
  duration = 250,
  easing = 'easeInOutQuad',
  onComplete,
}: {
  target?: number;
  duration?: number;
  easing?: EasingType;
  onComplete?: () => void;
}) => {
  if (!isBrowserWindowAvailable()) return;

  const startingY = window.scrollY;
  const diff = target - startingY;

  let start: number | undefined;

  const step = (timestamp: number) => {
    if (!start) start = timestamp;

    const time = timestamp - start;
    const percent = Math.min(time / duration, 1);
    const easedPercent = ease(percent, easing);

    window.scrollTo(0, startingY + diff * easedPercent);

    if (time < duration) {
      // next step
      window.requestAnimationFrame(step);
    } else if (onComplete) {
      // done
      onComplete();
    }
  };

  // begin!!
  window.requestAnimationFrame(step);
};

export default animatedScroll;
