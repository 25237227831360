import { css } from '@emotion/react';
import { Flex, Grid } from 'components/box';
import { memo } from 'react';
import { fadeAnimations } from '../../../../shared/style';
import { GalleryImageInterface } from 'types/types';
import ResponsiveImage from 'components/_shared/elements/responsive-image';
import { INTERNAL_LINKS, CURSOR } from './constants';

const ImageContainer = ({
  url,
  widths,
  sizes,
  cursorType,
  altText,
}: {
  url: string;
  widths: number[];
  sizes: string[];
  cursorType?: 'pointer';
  altText?: string;
}) => (
  <Grid
    gridRowGap={1}
    gridColumnGap={0}
    css={theme => css`
      border-radius: ${theme.radii[3]}px;
      box-shadow: ${theme.shadows.products};
      position: relative;
      ${cursorType &&
      css`
        cursor: ${cursorType};
      `}
      overflow: hidden;
      backface-visibility: hidden;
      will-change: transform;

      .image {
        transition: all 0.3s ease;
      }

      ${fadeAnimations};

      &:hover {
        box-shadow: ${theme.shadows.productsHover};
        .image {
          transform: scale(1.04);
        }
      }
    `}
  >
    <Flex
      tabIndex={0}
      alignItems="flex-start"
      css={css`
        &:after {
          content: '';
          display: block;
          padding-bottom: 100%;
        }
      `}
    >
      <ResponsiveImage
        altText={altText}
        title="image"
        url={url}
        widths={widths}
        sizes={sizes}
      />
    </Flex>
  </Grid>
);

interface GalleryImageProps {
  image: GalleryImageInterface;
  widths: number[];
  sizes: string[];
}

const GalleryImageSkeleton = memo(() => (
  <div
    css={theme => css`
      background-color: ${theme.colors.skeletonBackground};
      border-radius: ${theme.radii[3]}px;

      /* square */
      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    `}
  />
));

const GalleryImage = memo(function GalleryImage({
  image: galleryImage,
  ...restProps
}: GalleryImageProps) {
  const { link, image, altText } = galleryImage;
  const openInNewTab =
    !!link && !INTERNAL_LINKS.find(item => link.startsWith(item));

  return (
    <>
      {!!link && (
        <a
          title={image.label}
          tabIndex={-1}
          className="gallery-item"
          target={openInNewTab ? '_blank' : undefined}
          rel="noopener noreferrer"
          href={link}
        >
          <ImageContainer
            altText={altText}
            cursorType={CURSOR}
            url={image.url}
            {...restProps}
          />
        </a>
      )}
      {!link && (
        <ImageContainer altText={altText} url={image.url} {...restProps} />
      )}
    </>
  );
});

const Wrapper = ({
  isSkeleton,
  ...props
}: GalleryImageProps & { isSkeleton?: boolean }) => {
  if (isSkeleton) {
    return <GalleryImageSkeleton />;
  }

  return <GalleryImage {...props} />;
};

export default Wrapper;
