import { useRef } from 'react';
import { useOnScreen } from 'hooks/display';
import HeroGallery from 'components/_shared/widgets/gallery/hero-gallery';
import Gallery from 'components/_shared/widgets/gallery';
import Shop from 'components/shop';
import {
  ProductListingProps,
  ListingItem,
  isHero,
  isShop,
  isGallery,
  isSimpleGalley,
} from './types';
import ErrorBoundary from 'components/_global/error-boundary';

const LAZY_LISTING_MIN = 10;
const SHOW_FIRST_X_BY_DEFAULT = 2;
const ROOT_MARGIN = '1500px';

const Item = ({ show = true, ...item }: ListingItem & { show?: boolean }) => (
  <ErrorBoundary>
    {isHero(item) && <HeroGallery isSkeleton={!show} {...item.props} />}
    {isShop(item) && <Shop isSkeleton={!show} {...item.props} />}
    {isGallery(item) && <Gallery isSkeleton={!show} {...item.props} />}
    {isSimpleGalley(item) && <Gallery isSkeleton={!show} {...item.props} />}
  </ErrorBoundary>
);

const LazyItem = ({
  showByDefault,
  ...props
}: ListingItem & { showByDefault?: boolean }) => {
  const ref = useRef<HTMLDivElement>(null);
  const show = useOnScreen({ ref, showByDefault, rootMargin: ROOT_MARGIN });

  return (
    <div ref={ref}>
      <Item show={show} {...props} />
    </div>
  );
};

const ProductListing = ({ items, isLazy = true }: ProductListingProps) => (
  <>
    {isLazy && items.length >= LAZY_LISTING_MIN ? (
      <>
        {items.map((props, idx) => (
          <LazyItem
            key={idx}
            showByDefault={idx < SHOW_FIRST_X_BY_DEFAULT}
            {...props}
          />
        ))}
      </>
    ) : (
      <>
        {items.map((props, idx) => (
          <Item key={idx} {...props} />
        ))}
      </>
    )}
  </>
);

export default ProductListing;
