import { ShopProps } from 'components/shop';
import {
  GalleryProps,
  Layout,
  LayoutProps,
} from 'components/_shared/widgets/gallery';
import { SimpleGalleryInterface } from 'types/types';

export enum ListingTypeEnum {
  Hero = 'hero',
  Shop = 'shop',
  Gallery = 'gallery',
  SimpleGallery = 'simpleGallery',
}

export type HeroItem = {
  type: ListingTypeEnum.Hero;
  props: GalleryProps;
};

export type ShopItem = {
  type: ListingTypeEnum.Shop;
  props: ShopProps;
};

export type GalleryItem = {
  type: ListingTypeEnum.Gallery;
  layout: Layout;
  props: GalleryProps;
};

export type SimpleGalleryItem = {
  type: ListingTypeEnum.SimpleGallery;
  props: SimpleGalleryProps;
};

export interface SimpleGalleryProps {
  items: SimpleGalleryInterface[];
  layout: LayoutProps;
  isSkeleton?: boolean;
  preloadImages?: boolean;
}

export type ListingItem = HeroItem | ShopItem | GalleryItem | SimpleGalleryItem;

export interface ProductListingProps {
  items: ListingItem[];
  isLazy?: boolean;
}

export const isHero = (props: ListingItem): props is HeroItem =>
  (props as HeroItem).type === ListingTypeEnum.Hero;
export const isShop = (props: ListingItem): props is ShopItem =>
  (props as ShopItem).type === ListingTypeEnum.Shop;
export const isGallery = (props: ListingItem): props is GalleryItem =>
  (props as GalleryItem).type === ListingTypeEnum.Gallery;
export const isSimpleGalley = (
  props: ListingItem
): props is SimpleGalleryItem =>
  (props as SimpleGalleryItem).type === ListingTypeEnum.SimpleGallery;
