import { css } from '@emotion/react';
import { Flex, Grid } from 'components/box';
import { memo } from 'react';
import { fadeAnimations } from '../../../../shared/style';
import { SimpleGalleryInterface } from 'types/types';
import ResponsiveImage from 'components/_shared/elements/responsive-image';
import { CURSOR } from './constants';

const ImageContainer = ({
  url,
  widths,
  sizes,
  cursorType,
}: {
  url: string;
  widths: number[];
  sizes: string[];
  cursorType?: 'pointer';
}) => (
  <Grid
    gridRowGap={1}
    gridColumnGap={0}
    css={theme => css`
      border-radius: ${theme.radii[3]}px;
      box-shadow: ${theme.shadows.products};
      position: relative;
      ${cursorType &&
      css`
        cursor: ${cursorType};
      `}
      overflow: hidden;
      backface-visibility: hidden;
      will-change: transform;

      .image {
        transition: all 0.3s ease;
      }

      ${fadeAnimations};

      &:hover {
        box-shadow: ${theme.shadows.productsHover};
        .image {
          transform: scale(1.04);
        }
      }
    `}
  >
    <Flex
      alignItems="flex-start"
      css={css`
        &:after {
          content: '';
          display: block;
          padding-bottom: 100%;
        }
      `}
    >
      <ResponsiveImage title="image" url={url} widths={widths} sizes={sizes} />
    </Flex>
  </Grid>
);

interface GalleryProductImageProps {
  image: SimpleGalleryInterface;
  widths: number[];
  sizes: string[];
}

const GalleryProductImageSkeleton = memo(
  ({ image }: GalleryProductImageProps) => (
    <div
      id={image.id}
      css={theme => css`
        background-color: ${theme.colors.skeletonBackground};
        border-radius: ${theme.radii[3]}px;

        /* square */
        &:after {
          content: '';
          display: block;
          padding-bottom: 100%;
        }
      `}
    />
  )
);

const GalleryProductImage = memo(function GalleryImage({
  image: galleryImage,
  ...restProps
}: GalleryProductImageProps) {
  const { image, id } = galleryImage;

  return (
    <a
      className="gallery-item"
      rel="noopener noreferrer"
      href={`/products/${id}`}
      id={id}
    >
      <ImageContainer cursorType={CURSOR} url={image.url} {...restProps} />
    </a>
  );
});

const Wrapper = ({
  isSkeleton,
  ...props
}: GalleryProductImageProps & { isSkeleton?: boolean }) => {
  if (isSkeleton) {
    return <GalleryProductImageSkeleton {...props} />;
  }

  return <GalleryProductImage {...props} />;
};

export default Wrapper;
