import {
  GalleryItem,
  GalleryImageInterface,
  ProductInterface,
  SimpleGalleryInterface,
} from 'types/types';

export const isImage = (item: GalleryItem): item is GalleryImageInterface =>
  typeof (item as GalleryImageInterface).image === 'object' &&
  typeof (item as GalleryImageInterface).image.url === 'string' &&
  (typeof (item as GalleryImageInterface).image.label === 'string' ||
    typeof (item as GalleryImageInterface).image.path === 'string') &&
  typeof (item as GalleryImageInterface).position === 'number' &&
  (item as GalleryImageInterface)._galleryType === 'image';

export const isProduct = (item: GalleryItem): item is ProductInterface =>
  typeof (item as ProductInterface).id === 'string' &&
  typeof (item as ProductInterface).realId === 'number' &&
  typeof (item as ProductInterface).sku === 'string' &&
  typeof (item as ProductInterface).name === 'string' &&
  typeof (item as ProductInterface).shortName === 'string' &&
  typeof (item as ProductInterface).brand === 'string' &&
  typeof (item as ProductInterface).hasPriceRange === 'boolean' &&
  typeof (item as ProductInterface).isSoldOut === 'boolean' &&
  typeof (item as ProductInterface).price.value === 'number' &&
  typeof (item as ProductInterface).minPrice.value === 'number' &&
  typeof (item as ProductInterface).image === 'object' &&
  typeof (item as ProductInterface).image.id === 'string' &&
  typeof (item as ProductInterface).image.url === 'string';

export const isGiftVoucherGalleryItem = (
  item: GalleryItem
): item is SimpleGalleryInterface =>
  typeof (item as SimpleGalleryInterface).id === 'string' &&
  typeof (item as SimpleGalleryInterface).image === 'object' &&
  typeof (item as SimpleGalleryInterface).image.id === 'string' &&
  typeof (item as SimpleGalleryInterface).image.url === 'string' &&
  (item as SimpleGalleryInterface)._galleryType === 'simple';
