import { memo } from 'react';
import GalleryProduct, { ProductSizes, prepProductSizes } from './product';
import GalleryImage from './image';
import GiftVoucherGalleryItem from './gift-voucher';
import { ItemSizeEnum } from './shared';
import { Box, Flex } from 'components/box';
import { GalleryItem } from 'types/types';
import {
  isGiftVoucherGalleryItem,
  isImage,
  isProduct,
} from 'types/guards/gallery';
import { css } from '@emotion/react';
import mem from 'mem';
import { logError } from '../../../../util/error-logger';

export type RowSize = 1 | 2 | 3 | 4;

const getRowSizeAsFlex = (sizes: RowSize[]) =>
  sizes.map(size => `0 1 ${100 / size}%`);

const getItemSizes = (sizes: RowSize[]): ItemSizeEnum[] =>
  sizes.map(
    size =>
      ({
        1: ItemSizeEnum.extraLarge,
        2: ItemSizeEnum.large,
        3: ItemSizeEnum.medium,
        4: ItemSizeEnum.small,
      }[size])
  );

/**
 * A single row size will set that size for all items
 * An array of row sizes will lay the items out accordingly
 * (eg. 3, 4, 3 creates 3 rows of items, first & third row having 3 items, and second row having 4)
 */
export type ScreenRows = RowSize | RowSize[];
export type Layout = [ScreenRows, ScreenRows, ScreenRows];

export type LayoutProps = {
  rowSizeAsFlex: string[];
  productSizes: ProductSizes;
  screenSize: RowSize[];
}[];

export const prepGallerySizes = mem(
  (count: number, layout: Layout): LayoutProps => {
    return new Array(count).fill(null).map((_, idx) => {
      const screenSize: RowSize[] = [];
      layout.forEach((screen, screenIdx) => {
        if (typeof screen === 'number') {
          screenSize.push(screen);
          return;
        }

        let agg = 0;
        screen.forEach(row => {
          agg += row;
          if (screenSize.length <= screenIdx && idx < agg) {
            screenSize.push(row);
          }
        });
      });

      const rowSizeAsFlex = getRowSizeAsFlex(screenSize);
      const mapped = getItemSizes(screenSize);

      return {
        screenSize,
        rowSizeAsFlex,
        productSizes: prepProductSizes(mapped, screenSize),
      };
    });
  },
  { cacheKey: ([count, layout]) => `${count}.${JSON.stringify(layout)}` }
);

export interface GalleryProps {
  items: GalleryItem[];
  layout: LayoutProps;
  isFirstRowAndLargeAccented?: boolean;
  isSkeleton?: boolean;
  preloadImages?: boolean;
}

const Gallery = ({
  items,
  layout,
  isFirstRowAndLargeAccented,
  isSkeleton,
  preloadImages,
}: GalleryProps) => (
  <Flex flexWrap="wrap" flexDirection="row" ml={[-3, -4]} mb={-3}>
    {items.map((item, idx) => {
      const layoutItem = layout[idx];
      if (typeof layoutItem === 'undefined') {
        logError(
          new Error(
            `Gallery with more items than it's layout is built for. ${JSON.stringify(
              { layout, items }
            )}`
          ),
          'Gallery'
        );
        return null;
      }

      const { rowSizeAsFlex, productSizes } = layoutItem;

      return (
        <Box
          flex={rowSizeAsFlex}
          key={isProduct(item) ? item.id : `${item.image.url}-${item.position}`}
          css={theme => css`
            margin-bottom: ${theme.space[4]}px;
            padding-left: ${theme.space[4]}px;
            @media ${theme.mediaQueries.mobileOnly} {
              padding-left: ${theme.space[3]}px;
            }
          `}
        >
          {isGiftVoucherGalleryItem(item) && (
            <GiftVoucherGalleryItem
              image={item}
              widths={productSizes.imageWidths}
              sizes={productSizes.imageSizes}
              isSkeleton={isSkeleton}
            />
          )}
          {isImage(item) && (
            <GalleryImage
              image={item}
              widths={productSizes.imageWidths}
              sizes={productSizes.imageSizes}
              isSkeleton={isSkeleton}
            />
          )}
          {isProduct(item) && (
            <GalleryProduct
              isSkeleton={isSkeleton}
              product={item}
              sizes={productSizes}
              preloadImage={preloadImages}
              isAccented={
                isFirstRowAndLargeAccented
                  ? layout.map(({ screenSize }, layoutIdx) => {
                      if (
                        productSizes.screenSizes[layoutIdx] ===
                          ItemSizeEnum.extraLarge ||
                        (layoutIdx > 1 &&
                          productSizes.screenSizes[layoutIdx] ===
                            ItemSizeEnum.large)
                      ) {
                        return true;
                      }
                      if (!screenSize || !screenSize[0]) {
                        return false;
                      }
                      return idx < screenSize[0];
                    })
                  : [false, false, false]
              }
            />
          )}
        </Box>
      );
    })}
  </Flex>
);

export const MemoizedGallery = memo(Gallery);

export default Gallery;
