import Gallery, { GalleryProps, Layout } from '.';

export const HERO_GALLERY_SIZE = 6;

export const HERO_GALLERY_LAYOUT: Layout = [
  [1, 1, 2, 2],
  [2, 4],
  [2, 4],
];

const HeroGallery = ({ items, layout }: GalleryProps) => {
  if (items.length !== HERO_GALLERY_SIZE) {
    console.warn(
      `Expected ${HERO_GALLERY_SIZE} items got ${items.length}.` +
        `Any extra/fewer items will create empty blocks in the layout on different screen sizes.`
    );
  }

  return (
    <Gallery
      items={items}
      layout={layout}
      isFirstRowAndLargeAccented
      preloadImages
    />
  );
};

export default HeroGallery;
