export const INTERNAL_LINKS = [
  'https://www.onedayonly.co.za',
  'https://dev.onedayonly.co.za',
  'http://dev.onedayonly.co.za',
  'https://qa.onedayonly.co.za',
  'http://qa.onedayonly.co.za',
  'https://staging.onedayonly.co.za',
  'http://staging.onedayonly.co.za',
  'http://localhost:3000/',
  '/products',
];

export const CURSOR = 'pointer';
